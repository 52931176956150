import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.current,
    currentCustomerId: (state) => state.current.customer?.id,
    currentCustomerName: (state) => state.current.customer?.common_name,
    currentCustomerUserId: (state) =>
      state.current.customer?.customers_user_id,
  }),
  ...mapGetters('auth', {
    beUsername: 'getCurrentUsername',
  }),
  ...mapGetters('auth', ['loggedIn', 'canUser', 'canUserSee', 'denyUser', 'userType']),
}

export const authMethods = mapActions('auth', ['logIn', 'logOut'])

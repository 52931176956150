<template>
  <v-app-bar
    app
    flat
    :class="[dark_mode ? 'shadow-dark' : 'shadow', 'justify-start']"
  >
    <v-app-bar-nav-icon style="width: 120px">
      <v-img
        src="@assets/ennova_logotype.svg"
        contain
        height="48"
        width="100"
        class="my-3 mx-5"
      />
    </v-app-bar-nav-icon>
    <v-toolbar-title>
      <span class="text-uppercase font-weight-light"> Assistente </span>
      <span class="text-uppercase font-weight-bold"> ONLINE </span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { authComputed } from '@state/helpers.js'

export default {
  name: 'AppBarPulbic',
  components: {},
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...authComputed,
    ...mapState('ui', ['dark_mode']),
  },
  methods: {
    ...mapMutations('ui', {
      toggleDrawer: 'TOGGLE_DRAWER',
    }),
  },
}
</script>

<style scoped>
.shadow {
  box-shadow: 0 2px 30px -1px rgba(85, 85, 85, 0.08),
    0 4px 20px 0 rgba(85, 85, 85, 0.06), 0 1px 20px 0 rgba(85, 85, 85, 0.03) !important;
}

.shadow-dark {
  box-shadow: 0 2px 30px -1px rgba(0, 0, 0, 0.08),
    0 4px 20px 0 rgba(0, 0, 0, 0.06), 0 1px 20px 0 rgba(0, 0, 0, 0.03) !important;
}

.appbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.appbar-dark {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
</style>
